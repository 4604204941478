import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const moduleUser = {
  state: {
    currentUser: null,
  },
  mutations: {
    userStatus(state, user) {
      if (user) {
        state.currentUser = user
      } else {
        state.currentUser = null
      }
    }
  },
  actions: {
    setUser(context, user) {
      context.commit('userStatus', user)
    }

  },
  getters: {
    getUserInfo: state => {
      return state.currentUser
    },
    getUserName: state => {
      if (state.currentUser) {
        return state.currentUser.username
      }
      return null
    },
    getUserNickName: state => {
      if (state.currentUser) {
        return state.currentUser.nickname
      }
      return null
    },
    getUserAccountState: state => {
      if (state.currentUser) {
        return state.currentUser.account_state
      }
      return null
    },
    getUserValidDate: state => {
      if (state.currentUser) {
        return state.currentUser.validate_date
      }
      return null
    },
    isLogin: state => {
      if (state.currentUser == null) {
        return false
      } else {
        return true
      }
    }
  }

}

const moduleTest = {
  state: {
    testStart: false,
    testResult: null
  },
  mutations: {
    setTestStart(state, start) {
      state.testStart = start
    },
    setTestResult(state, result) {
      state.testResult = result
    },
    clearTestResult(state) {
      state.testResult = null
    }
  },
  getters: {
    isTestStart: state => {
      return state.testStart
    },
    getTestResult: state => {
      return state.testResult
    }
  }
}

const screenSize = {
  state: {
    screenWidth: 0,
    screenHeight: 0
  },
  mutations: {
    setCScreenWidth(state, value) {
      state.screenWidth = value
    },
    setCScreenHeight(state, value) {
      state.screenHeight = value
    }
  },
  actions: {
  },
  getters: {
    getScreenWidth: state => {
      return state.screenWidth
    },
    getScreenHeight: state => {
      return state.screenHeight
    },
  },
}

export default new Vuex.Store({
  // state: {
  //   screenWidth: 0,
  //   screenHeight: 0
  // },
  // mutations: {
  //   setCScreenWidth(state, value) {
  //     state.screenWidth = value
  //   },
  //   setCScreenHeight(state, value) {
  //     state.screenHeight = value
  //   }
  // },
  // actions: {
  // },
  // getters: {
  //   getScreenWidth: state => {
  //     return state.screenWidth
  //   },
  //   getScreenHeight: state => {
  //     return state.screenHeight
  //   },
  // },
  modules: {
    userInfo: moduleUser,
    testInfo: moduleTest,
    screenInfo: screenSize
  }
})
