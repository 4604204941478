import Vue from 'vue'
import VueRouter, { RawLocation, RouteConfig } from 'vue-router'
import store from '@/store'
// import Demo from '../views/Demo.vue'
// import Main from '../views/Main.vue'
// import Home from '../views/home/Home.vue'
// import Login from '../views/Login.vue'
// import User from '../views/user/user.vue'
// import UserCenter from '../views/user/userCenter.vue'
// import UserHistory from '../views/user/userHistory.vue'
// import BaseTest from '../views/common/BaseTest.vue'
// import PlaneLogic from '../views/logic/PlaneLogic.vue'
// import SolidRotation from '../views/logic/SolidRotation.vue'
// import SolidSpread from '../views/logic/SolidSpread.vue'
// import CodeCalc from '../views/logic/CodeCalc.vue'
// import PatternMatch from '../views/logic/PatternMatch.vue'
// import SchulteGrid from '../views/logic/SchulteGrid.vue'
// import MentalCalc from '../views/logic//mentalCalc.vue'
// import Register from '../views/user/register.vue'
// import Dashboard from '../views/user/dashboard.vue'

Vue.use(VueRouter)
// 是否移动端检查
const isMobile = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)

const mapRouteModule = function (resolve, file) {
  if (isMobile) {
    return require(['../views/mobile/' + file], resolve)
  } else {
    return require(['../views/pc/' + file], resolve)
  }
}

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location: RawLocation) {
  // 使用(y as any).catch() 方式解决 Property 'catch' does not exist on type 'void' 问题
  return (originalPush.call(this, location) as any).catch((err: any) => err)
}

const routes: Array<RouteConfig> = [
  {
    path: '/main',
    // name: 'main',
    component: resolve => mapRouteModule(resolve, 'Main.vue'),
    children: [
      {
        path: '',
        component: resolve => mapRouteModule(resolve, 'user/dashboard.vue'),
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: resolve => mapRouteModule(resolve, 'user/dashboard.vue'),
        // component: Dashboard
      },
      {
        path: 'user',
        name: 'user',
        component: resolve => mapRouteModule(resolve, 'user/user.vue'),
        // component: User
      },
      // {
      //   path: 'test',
      //   component: BaseTest,
      //   children: [
      //     {
      //       path: 'plane-logic',
      //       component: resolve => mapRouteModule(resolve, 'logic/PlaneLogic.vue'),
      //       // component: PlaneLogic
      //     },
      //   ]
      // },
      {
        path: 'plane-logic',
        name: 'plane-logic',
        component: resolve => mapRouteModule(resolve, 'logic/PlaneLogic.vue'),
        // component: PlaneLogic
      },
      {
        path: 'solid-rotation',
        name: 'solid-rotation',
        component: resolve => mapRouteModule(resolve, 'logic/SolidRotation.vue'),
        // component: SolidRotation
      },
      {
        path: 'solid-spread',
        name: 'solid-spread',
        component: resolve => mapRouteModule(resolve, 'logic/SolidSpread.vue'),
        // component: SolidSpread
      },
      {
        path: 'code-calc',
        name: 'code-calc',
        component: resolve => mapRouteModule(resolve, 'logic/CodeCalc.vue'),
        // component: CodeCalc
      },
      {
        path: 'pattern-match',
        name: 'pattern-match',
        component: resolve => mapRouteModule(resolve, 'logic/PatternMatch.vue'),
        // component: PatternMatch
      },
      {
        path: 'schulte-grid',
        name: 'schulte-grid',
        component: resolve => mapRouteModule(resolve, 'logic/SchulteGrid.vue'),
        // component: SchulteGrid
      },
      {
        path: 'mental-calc',
        name: 'mental-calc',
        component: resolve => mapRouteModule(resolve, 'logic/MentalCalc.vue'),
        // component: MentalCalc
      },
      {
        path: 'user-center',
        name: 'user-center',
        component: resolve => mapRouteModule(resolve, 'user/userCenter.vue')
        // component: UserCenter
      },
      {
        path: 'user-history',
        name: 'user-history',
        component: resolve => mapRouteModule(resolve, 'user/userHistory.vue')
        // component: UserHistory
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: resolve => mapRouteModule(resolve, 'Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: resolve => mapRouteModule(resolve, 'user/register.vue')
    // component: Register
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: resolve => mapRouteModule(resolve, 'forgetPassword.vue')
    // component: Register
  },
  {
    path: '/demo',
    name: 'Demo',
    component: resolve => mapRouteModule(resolve, 'Demo.vue')
    // component: Demo
    // component: Register
  },
  // {
  //   path: '/user',
  //   name: 'user',
  //   component: User
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '*',
    redirect: '/main/dashboard'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 检查测试状态
  if (store.getters.isTestStart === true) {
    alert('请结束当前测试!')
  } else next()
})

export default router
