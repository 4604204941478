<template>
  <v-app id="app">
    <v-main class="mx-4 mb-4">
      <router-view></router-view>
    </v-main>
    <v-footer padless class="mt-2">
      <v-row justify="center" no-gutters v-if="!_isMobile()">
        <v-col class="py-4 text-center" cols="12">
          <!-- 待域名备案完成后打开 -->
          <!-- <span class="footer-style">@copyright</span> -->
          <div style="width: 550px; margin: 0 auto">
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              class="footer-style"
              >黑ICP备2021001867号-1</a
            >
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              class="footer-style"
            >
              <img src="../src/assets/beian/beian.png" style="float: left" />
              黑公网安备 23010202010524号</a
            >
          </div>
        </v-col>
        <!-- <v-col class="py-4 text-center" cols="12" sm="2">
          <div style="width: 300px; margin: 0 auto">
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=23010202010524"
              style="
                display: inline-block;
                text-decoration: none;
                height: 15px;
                line-height: 15px;
              "
              ><img src="../src/assets/beian/beian.png" style="float: left" />
              <p class="footer-style">黑公网安备 23010202010524号</p></a
            >
          </div>
        </v-col> -->
      </v-row>
      <v-row justify="center" no-gutters v-if="_isMobile()">
        <v-col class="py-4 text-center" cols="12">
          <!-- 待域名备案完成后打开 -->
          <!-- <span class="footer-style">@copyright</span> -->
          <div style="width: 300px; margin: 0 auto">
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              class="footer-style-m"
              >黑ICP备2021001867号-1
            </a>
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              class="footer-style-m"
              >黑公网安备 23010202010524号</a
            >
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'App',

  components: {},
  mounted() {
    window.onresize = () => {
      window.screenWidth = document.body.clientWidth
      window.screenHeight = document.body.clientHeight
      this.screenWidth = window.screenWidth
      this.screenHeight = window.screenHeight
    }
    this.$store.commit('setCScreenWidth', this.screenWidth)
    this.$store.commit('setCScreenHeight', this.screenHeight)
  },
  watch: {
    screenWidth: {
      handler() {
        this.$store.commit('setCScreenWidth', this.screenWidth)
      },
    },
    screenHeight: {
      handler() {
        this.$store.commit('setCScreenHeight', this.screenHeight)
      },
    },
  },

  data: () => ({
    screenWidth: document.body.clientWidth,
    screenHeight: document.body.clientHeight,
  }),

  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
  },
})
</script>

<style lang="scss" scoped>
.footer-style {
  float: left;
  height: 12px;
  line-height: 12px;
  margin: 0px 0px 0px 5px;
  color: #939393;
  // font-size: 10px;
  // color: grey;
}
.footer-style-m {
  float: left;
  font-size: 60%;
  // height: 5px;
  // line-height: 5px;
  margin: 0px 0px 0px 5px;
  color: #939393;
  // font-size: 10px;
  // color: grey;
}
</style>
